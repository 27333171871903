.player {
  margin-top: 16px;

  .name {
    font-size: 1.5em;
  }

  .matches {
    margin-top: 16px;
  }
}
