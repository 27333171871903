@import "../../common/variables.scss";

.league-selector {
  display: flex;
  text-align: center;
  column-gap: 8px;

  &.mobile {
    position: fixed;
    bottom: 0;
    width: 100vw;
    justify-content: space-between;
    border-top: 3px solid $gray-color;
    background-color: white;
    column-gap: 0;

    div {
      padding: 16px 8px;
      width: 20%;
      flex: 1 1 0;

      &.active {
        background-color: $primary-color;
      }
    }
  }

  &.desktop {
    margin-right: 16px;
    margin-left: auto;
    align-items: center;
  }

  div {
    flex: 1 1 0;
    line-height: 20px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    &.active {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
