@import "../../common/variables.scss";

.pagination {
  display: flex;
  column-gap: 8px;
  justify-content: center;

  div {
    &.active {
      font-weight: bold;
      color: $primary-color;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
