.day-selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;

  .react-datepicker-wrapper {
    margin: 0 16px;
    width: 20ch;

    input {
      text-align: center;
    }
  }
}
