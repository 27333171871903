@import "./common/variables.scss";

.App {
  text-align: center;

  h1,
  h2,
  h3 {
    font-weight: bold;
    margin-bottom: 0;
  }

  .table,
  .matches {
    max-width: 1000px;
    margin: auto;
  }

  .calendar {
    max-width: 100vw;
    margin: auto;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.badge {
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;

  &-1 {
    background-color: $primary-color;
  }

  &-2 {
    background-color: rgba($color: $primary-color, $alpha: 0.5);
  }
}
